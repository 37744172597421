@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
li {
  font-family: "Quicksand", sans-serif;
}
 :root{
  --customBlue:#131D29;
  --customGold:#cb935d;
  --customWhite:#F2F2F0;

 }