.navContainer {
  /* border: solid; */
  height: 12vh;
  width: 100%;
  background-color: #1f3044;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 6;
  gap: 50%;
}
.navContainer1 {
  /* border: solid; */
  height: 12vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.229);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  top: 0;
  color: white;
  z-index: 4;
  gap: 50%;
}
.menuContainer {
  /* border: solid red; */
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.menuContainer > li {
  list-style: none;
}
.menuContainer > li > a {
  text-decoration: none;
  color: white;
}
.logo {
  /* border: solid red; */
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo > a > img {
  width: 100%;
  object-fit: cover;
}
.menuBar {
  font-size: 30px;
  display: none;
}
.respMenuContainer {
  /* border: solid red; */
  position: absolute;
  width: 70%;
  height: 100vh;
  top: 0%;
  right: 0;
  background-color: #1f3044bc;
  backdrop-filter: blur(10px);
  overflow: hidden;
  transition: 600ms ease-in-out;
  display: none;
  transform: translateX(100%);
}

.respMenuContainer > li {
  list-style: none;
  width: 100%;
  border-bottom: 1px solid var(--customGold);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}
.respMenuContainer > li > a {
  color: white;
  text-decoration: none;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .navContainer {
    /* border: solid; */
    height: 12vh;
    width: 100%;
    background-color: #1f3044;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 6;
    gap: 50%;
  }
  .navContainer1 {
    /* border: solid; */
    height: 12vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.229);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0;
    color: white;
    z-index: 4;
    gap: 50%;
  }
  .menuContainer > li {
    border: solid;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    display: none;
  }
  .logo {
    /* border: solid red; */
    height: 100%;
    width: 700px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo > a > img {
    width: 100%;
    object-fit: cover;
  }
  .respMenuContainer {
    display: block;
  }
  .menuBar {
    display: block;
  }
}
@media screen and (min-width: 780px) and (max-width: 979px) {
  .navContainer {
    /* border: solid; */
    gap: 10%;
  }
  .navContainer1 {
    /* border: solid; */
    gap: 10%;
  }
  .logo > a > img {
    width: 50%;
    object-fit: cover;
  }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .navContainer {
    /* border: solid; */
    gap: 10%;
  }
  .navContainer1 {
    /* border: solid; */
    gap: 10%;
  }
  .logo > a > img {
    width: 70%;
    object-fit: cover;
  }
  .menuContainer {
    /* border: solid ; */
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }
  .menuContainer > li {
    list-style: none;
  }
  .menuContainer > li > a {
    text-decoration: none;
    color: white;
    font-size: 20px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .navContainer {
    /* border: solid; */
    gap: 10%;
  }
  .navContainer1 {
    /* border: solid; */
    gap: 10%;
  }
  .logo > a > img {
    width: 70%;
    object-fit: cover;
  }
  .menuContainer {
    /* border: solid ; */
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }
  .menuContainer > li {
    list-style: none;
  }
  .menuContainer > li > a {
    text-decoration: none;
    color: white;
    font-size: 20px;
  }
}
