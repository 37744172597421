.breadCrumbsContainer {
  /* border: solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 95%;
  padding: 20px;
}
.breadCrumbsContainer > a {
  font-size: 20px;
  color: var(--customBlue);
  font-weight: bold;
  text-decoration: none;
}
.arrow {
  color: var(--customGold);
}
.breadCrumbsContainer > span {
  color: var(--customGold);
  font-size: 20px;
  font-weight: bold;
}

@media screen and (min-width:320px) and (max-width:768px) {
  .breadCrumbsContainer > a {
    font-size: 15px;

  }
  .breadCrumbsContainer > span {
    font-size: 15px;
    text-align: center;
  }
  
}