.homeSolutionContainer {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  width: 100%;
  background-color: var(--customWhite);
}
.homeSolutionHeaderContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
.homeSolutionHeaderContainer > h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.homeSolutionHeaderContainer > h2 > a{
  text-decoration: none;
}
.homeSolutionHeaderContainer > h2 > a > span {
  color: #c9925d;
  transition: 100ms ease-in-out;
}
.homeSolutionHeaderContainer > h2 > a>span:hover {
  color: #131d29;
}
.homeSolutionCardContainer {
  /* border: solid green; */
  height: 100vh;
}
.homeSolutionMainContainer {
  /* border: solid blue; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeSolutionCardContainer {
  /* border: solid red; */
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
  padding: 10px;
}
.homeSolutionCard {
  border: solid #1f3044;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.solutionCard {
  /* border: solid ; */
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--customBlue);
  /* background-color: #cb935d; */
  transition: 200ms ease-in-out;
}
.homeSolutionCardHead > h3 {
  color: var(--customGold);
  text-align: center;
}
.homeSolutionCardPara {
  width: 90%;
}
.homeSolutionCardPara > p {
  text-align: center;
  font-size: 16px;
}
.solCal {
  font-size: 40px;
  color: white;
}
.homeSolutionCardContainer:hover .solutionCard {
  transform: scale(0.9);
  background-color: var(--customGold);
}
.homeSolutionCardContainer:hover .homeSolutionCard {
  border: solid var(--customGold);
}
.homeSolutionCardHead {
  font-size: 20px;
  font-weight: bold;
}
@media screen and (min-width: 320px) and (max-width: 700px) {
  .homeSolutionHeaderContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
  }
  .homeSolutionHeaderContainer > h5 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .homeSolutionHeaderContainer > h2 {
    text-align: center;
    width: 90%;
  }
  .homeSolutionHeaderContainer > h2 > span {
    color: #c9925d;
    transition: 500ms ease-in-out;
    text-align: center;
  }
  .homeSolutionHeaderContainer > h2 > span:hover {
    color: #131d29;
  }

  .homeSolutionMainContainer {
    /* border: solid blue; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .homeSolutionCardContainer {
    /* border: solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 20px;
  }
  .homeSolutionCard {
    border: solid #cb935d;
    border-radius: 100%;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .solutionCard {
    /* border: solid ; */
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cb935d;
    transition: 200ms ease-in-out;
  }
  .homeSolutionCardPara {
    width: 80%;
  }
  .homeSolutionCardPara > p {
    text-align: center;
  }
  .solCal {
    font-size: 40px;
    color: white;
  }

  .homeSolutionCardHead {
    font-size: 20px;
    font-weight: bold;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .homeSolutionCardPara > p {
    text-align: justify;
  }
  .homeSolutionCardPara {
    /* border: solid; */
    width: 80%;
  }
}
