.footerContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--customBlue);
  margin-top: 40px;
}
.footer1Container {
  /* border: solid blue; */
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
.footerAddContainer {
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 30%;
}
.footerAddContainer > p {
  font-size: 18px;
  width: 300px;
  color: var(--customGold);
  text-align: center;
  font-weight: bold;
}
.footerAddContainer > img {
  width: 40%;
}
.footerAddContent {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.footerMenuContainer > ul {
  width: 100%;
  /* border: solid pink; */
}
.footerMenuContainer > ul > h3 {
  color: var(--customGold);
  font-size: 20px;
}
.footerAddContent > li {
  margin: 20px;
  color: white;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 30px;
  width: 80%;
  text-align: start;
  list-style: none;
}
.footerAddContent > li > a {
  text-decoration: none;
  color: white;
}
.footerMenuContainer > ul > li {
  margin: 20px;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  text-align: start;
  font-size: 20px;
  list-style: none;
}
.footerMenuContainer > ul > li > a {
  text-decoration: none;
  color: white;
}
.footerMenuContainer > ul > li > a:hover {
  text-decoration: none;
  color: var(--customGold);
}
.footerIcon {
  font-size: 20px;
  color: var(--customGold);
}

.footerMenuContainer {
  /* border: solid yellow; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 20%;
  padding: 20px;
}
.footerSocialContainer {
  /* border: solid yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 20%;
  padding: 20px;
}
.footerSocialContainer > ul > li {
  margin: 20px;
}
.socialIcon {
  font-size: 30px;
  color: white;
}
.socialIcon:hover {
  color: var(--customGold);
}
.footerSocialContainer > ul > h3 {
  color: var(--customGold);
  font-size: 20px;
}
.footerSocialContainer > ul > li {
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footerUnderline {
  border: 1px solid white;
  width: 80%;
}

.footer2Container {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  background-color: var(--customGold);
  overflow: hidden;
}
.footer2Container > h3 {
  font-size: 18px;
  height: 100%;
  color: var(--customBlue);
}
.trySeo {
  /* border: solid blue; */
  width: 200px;
  height: 80px;
  position: relative;
}
.trySeo > a {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  position: absolute;
}
.trySeo > a > img {
  width: 100%;
  height: 100%;
  transform: scale(1.5);
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer1Container {
    /* border: solid blue; */
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footerAddContainer {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 100%;
  }

  .footerMenuContainer > ul {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    /* border: solid pink; */
  }
  .footerAddContent > li {
    margin: 20px;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    width: 80%;
    text-align: start;
    list-style: none;
  }
  .footerMenuContainer {
    /* border: solid yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
  }
  .footerSocialContainer {
    /* border: solid yellow; */
    gap: 20px;
    width: 100%;
    padding: 20px;
  }
  .footer2Container {
    /* border: solid red; */
    flex-direction: column;
    padding: 10px;
  }
  .footer2Container > h3 {
    font-size: 18px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .footer1Container {
    /* border: solid blue; */
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footerAddContainer {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: 100%;
  }

  .footerMenuContainer > ul {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    /* border: solid pink; */
  }
  .footerAddContent > li {
    margin: 20px;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    width: 80%;
    text-align: start;
    list-style: none;
  }
  .footerMenuContainer {
    /* border: solid yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px;
  }
  .footerSocialContainer {
    /* border: solid yellow; */
    gap: 20px;
    width: 100%;
    padding: 20px;
  }
  .footer2Container {
    /* border: solid red; */
    flex-direction: column;
    padding: 10px;
  }
  .footer2Container > h3 {
    font-size: 18px;
    text-align: center;
  }
}
