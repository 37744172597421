.homeSupportContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
  height: 100%;
}

.homeSupportContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}
.homeSupportCover {
  /* border: solid blue; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.homeSupportHeadContainer {
  /* border: solid yellow; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
}
.homeSupportHeadContainer > h2 {
  font-size: 40px;
  color: white;
}
.homeSupportHeadContainer > p {
  font-size: 20px;
  color: white;
}
.homeSupportHeadContainer > h2 > span {
  color: var(--customGold);
}
.homeSupportCoverContent {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#homeSupportCoverHead {
  height: 20%;
  padding: 20px;
}
#homeSupportCoverAcco {
  /* border: solid brown; */
  height: 80%;
}
.homeSupportMainContainer {
  /* border: solid yellow; */
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.accordionMain {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.accordionBody {
  /* border: solid green; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.accordionBody > p {
  color: black;
}
.accMainContent {
  width: 100%;
  height: 100%;
}
.accHead {
  /* border: solid green; */
  height: 10vh;
  width: 100%;
  margin-top: 40px;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 1px rgb(206, 204, 204);
  color: white;
  background-color: var(--customBlue);
}

.accPanel {
  box-shadow: 0px 3px 10px 1px rgb(206, 204, 204);
  margin-top: 20px;
  border-radius: 10px;
  background-color: #131d298e;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.accPanel > p {
  font-size: 20px;
  margin-left: 20px;
  margin: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: justify;
  gap: 20px;
}
.accPanel > p > h4 {
  color: var(--customGold);
}
.accPanel > p > button {
  font-size: 15px;
  font-weight: bold;
  color: white;
  padding: 10px;
  border: 2px solid var(--customGold);
  background-color: var(--customBlue);
  box-shadow: 5px 5px 0px #a87e55;
  transition: 200ms ease-in-out;
}
.accPanel > p > button > a {
  text-decoration: none;
  color: white;
}
.accPanel > p > button:hover {
  background-color: #a87e55;
  box-shadow: none;
}
.accBut {
  /* border: solid purple; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accBut > h3 {
  margin-left: 20px;
  font-size: 30px;
}
.dropIcon {
  font-size: 20px;
  padding: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .homeSupportCover {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    padding: 0px;
  }
  .homeSupportHeadContainer {
    /* border: solid yellow; */
    width: 100%;
    height: 100%;
    padding: 0px;
  }
  .homeSupportHeadContainer > h2 {
    font-size: 30px;
    color: white;
  }

  .accBut > h3 {
    margin-left: 20px;
    font-size: 20px;
  }
  .accPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .accPanel > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .accPanel > p {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
