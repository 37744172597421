@import url("https://fonts.googleapis.com/css2?family=Style+Script&display=swap");

.homeAboutContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
  height: 100vh;
}
.homeAboutContainer > img {
  width: 100%;
  height: 100%;
}
.homeAboutCover {
  /* border: solid yellowgreen; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: var(--customBlue);
  position: absolute;
}
.homeAboutHead {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.homeAboutHead > h2 {
  width: 100%;
  text-align: center;
  padding: 10px;
  text-align: center;
  color: white;
}
.homeAboutHead > h2 > span {
  color: var(--customGold);
}
.homeAboutHead > p {
  width: 80%;
  text-align: center;
  padding: 10px;
  font-size: 20px;
  color: white;
}
.homeAboutMain {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  padding: 10px;
}
.homeAboutMainContent {
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}
.homeAboutMainItem2 > p {
  color: white;
  width: 100%;
  text-align: center;
  font-size: 25px;
  width: 150px;
  font-weight: bold;
}
.homeAboutMainItem1 > h3 {
  font-size: 30px;
  color: white;
  text-align: center;
  width: 100%;
  font-family: "Style Script", cursive;
  font-weight: bold;
}
.homeAboutFooter {
  /* border: solid brown; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.homeAboutFooterContent > h4 {
  color: white;
}
.homeAboutFooterContent > h4 > a:hover {
  color: var(--customGold);
}
#homeAboutMainContent1 {
  margin-bottom: 100px;
}
#homeAboutMainContent2 {
  margin-top: 100px;
}
.homeAboutFooterContent > h4 > a {
  color: white;
  text-decoration: none;
}
@media screen and (min-width: 320px) and (max-width: 700px) {
  .homeAboutContainer {
    /* border: solid red; */
    gap: 0px;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .homeAboutCover {
    /* border:solid yellowgreen; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #131d29;
    position: static;
  }
  .homeAboutHead > p {
    text-align: justify;
    padding: 0px;
  }
  .homeAboutMain {
    /* border: solid green; */
    width: 100%;
    flex-direction: column;
    gap: 30px;
    padding: 10px;
  }
  #homeAboutMainContent1 {
    margin-bottom: 0px;
    margin-right: 50%;
  }
  #homeAboutMainContent2 {
    margin-top: 0px;
    margin-left: 50%;
  }
}
