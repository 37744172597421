.serviceTestimonialContainer {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 100%;
  background-color: var(--customBlue);
  overflow: hidden;
}
.serviceTestimonialHead {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.serviceTestimonialHead > h3 {
  font-size: 18px;
  color: var(--customGold);
  text-align: center;
}
.serviceTestimonialHead > h2 {
  font-size: 30px;
  text-align: center;
  color: white;
}
.serviceTestimonialHead > h2 > span {
  color: var(--customGold);
}
.serviceTestimonialHead > p {
  font-size: 20px;
  color: white;
  text-align: center;
  width: 40%;
}
.serviceTestimonialMain {
  /* border: solid blue; */
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.serviceTestimonialCard {
  /* border: solid green; */
  width: 30%;
  height: 100%;
  background-color: white;
}
.serviceTestimonialCardContent {
  /* border: solid yellowgreen; */
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.serviceTestimonialCardContent > p {
  font-size: 15px;
  text-align: justify;
}
.serviceTestimonialCardItem > p {
  font-size: 20px;
  font-weight: bold;
}
.serviceTestimonialCardItem > img {
  width: 60px;
}
.serviceTestimonialMain > p {
  color: white;
  font-size: 20px;
  text-align: justify;
}
.serviceTestimonialMain > p > a {
  text-decoration: none;
  color: var(--customGold);
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .serviceTestimonialContainer {
    /* border: solid red; */
    padding: 0px;
  }
  .serviceTestimonialHead > p {
    width: 80%;
  }
  .serviceTestimonialMain {
    /* border: solid blue; */
    width: 100%;
    padding: 10px;
    gap: 20px;
  }
  .serviceTestimonialCard {
    /* border: solid green; */
    min-width: 300px;
    background-color: white;
  }
  .serviceTestimonialMain > p {
    text-align: justify;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .serviceTestimonialMain {
    /* border: solid blue; */
    width: 100%;
  }
}
