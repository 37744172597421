.mainServiceContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 10px;
}
.mainServiceContent1 {
  width: 85%;
  /* border: solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.mainServiceContent1 > h1 {
  font-size: 30px;
  text-align: center;
  width: 100%;
}
.mainServiceContent1 > h1 > span {
  color: var(--customGold);
}
.mainServiceContent1 > p {
  font-size: 20px;
  width: 40%;
  text-align: center;
}
.mainServiceContent2 {
  /* border: solid yellow; */
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 30px;
}
.serviceCardContainer {
  /* border: solid green; */
  width: 30%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  transition: 200ms ease-in-out;
  margin: 10px;
  box-shadow: 0 0 5px black;
  position: relative;
  overflow: hidden;
}
.serviceCardContainer:hover .serviceCardImg {
  transform: translateY(-10px);
}
.serviceCardImg {
  width: 30%;
  transition: 200ms ease-in-out;
}
#serviceCardImgWhite {
  display: none;
}
.serviceCardContainer:hover #serviceCardImgBlack {
  display: none;
}
.serviceCardContainer:hover #serviceCardImgWhite {
  display: block;
}
.serviceCardContainer > h3 {
  text-align: center;
  font-size: 20px;
}
/* .serviceCardContainer > button {
  border: 2px solid var(--customGold);
  width: 100px;
  height: 40px;
  background-color: white;
} */
.serviceCardContainer > p {
  text-align: justify;
}
.serviceCardContainer > p > a {
  text-decoration: none;
  color: var(--customGold);
  font-size: 15px;
  font-weight: bold;
}
/* .serviceCardContainer > button:hover {
  border: 2px solid var(--custom);
  box-shadow: 6px 6px 0px var(--customGold);
} */
.serviceCardCover {
  width: 100%;
  height: 100%;
  background-color: var(--customBlue);
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  z-index: -1;
  transition: 200ms ease-in-out;
}
.serviceCardContainer:hover .serviceCardCover {
  transform: translateY(0%);
}
.serviceCardContainer:hover .serviceCardHead {
  color: white;
}
.serviceCardContainer:hover .serviceCardPara {
  color: white;
}
.leftArrow {
  color: var(--customGold);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .mainServiceContent1 {
    width: 100%;
    /* border: solid blue; */
  }
  .mainServiceContent1 > p {
    width: 100%;
  }
  .serviceCardContainer {
    /* border: solid green; */
    min-width: 300px;
    height: 100%;
  }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .mainServiceContent2 {
    /* border: solid yellow; */
    width: 100%;
  }
  .serviceCardContainer {
    /* border: solid green; */
    width: 30%;
    height: 400px;
  }
}
