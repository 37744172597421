.homeWhy {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.homeWhy > h2 {
  width: 80%;
  text-align: center;
  font-size: 25px;
  color: var(--customGold);
}
.homeWhy > p {
  width: 80%;
  text-align: justify;
  font-size: 20px;
}
.homeWhy > p > a {
  text-decoration: none;
  color: var(--customGold);
}
.homeWhy > li {
  /* border: solid green; */
  list-style: none;
  font-size: 20px;
  width: 80%;
  margin: 10px;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 20px;
  background-color: var(--customWhite);
}
.homeWhyList {
  /* border: solid blue; */
  list-style: none;
  font-size: 20px;
  width: 100%;
  text-align: justify;
  padding: 10px;
}

.homeWhyIcon {
  width: 10%;
  height: auto;
  /* background-color: var(--customBlue); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeWhyIcon > img {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.homeWhyList > span {
  color: var(--customGold);
  font-weight: bold;
  text-align: center;
}
.homeWhyList > p > a {
  text-decoration: none;
  color: var(--customGold);
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .homeWhy > li {
    /* border: solid green; */
    min-width: 300px;
    flex-direction: column;
    overflow: hidden;
  }
  .homeWhyIcon {
    width: 70%;
    border-radius: 20px;
  }
  .homeWhyList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
