.homeExpertiseContainer {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  background-color: var(--customWhite);
}

.homeExpertiseContainer {
  /* border: solid red; */
  height: 150vh;
}
.homeExpertiseContainer {
  /* border: solid blueviolet; */
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 0%;
}
.homeExpertiseContent {
  /* border: solid; */
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.homeExpertiseQuote,
.homeExpertiseHead,
.homeExpertisePara {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.homeExpertiseQuote > p {
  font-size: large;
}
.homeExpertiseHead > h1 {
  font-size: 50px;
  width: 100%;
  text-align: center;
}
.homeExpertiseHead > h1 > span {
  color: #a87e55;
}

.homeExpertisePara > p {
  /* border: solid; */
  width: 90%;
  text-align: center;
}

.homeExpertiseMainContainer {
  border: 0.1px solid grey;
  border-bottom: 1px;
  border-top: 1px;
  border-left: none;
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}
.homeExpertiseMainContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeExpertiseCardContainer {
  border: 1px solid grey;
  border-bottom: 1px;
  border-right: 1px;
  height: 34vh;
  width: 33.3%;
  position: relative;
  overflow: hidden;
}
.homeExpertiseCardCover {
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  transition: 500ms ease-in-out;
}
.homeExpertiseCardContainer > img {
  width: 100%;
  height: 100%;
  transition: 500ms ease-in-out;
}
.homeExpertiseCardContainer:hover .homeExpertiseCardCover {
  background-color: rgba(255, 191, 73, 0.752);
}
.homeExpertiseCardContainer:hover img {
  transform: scale(1.1);
}
.homeExpertiseCardTxtContainer {
  /* border: solid red; */
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.homeExpertiseCardIcon {
  /* border: solid blue; */
  height: 50%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeExpertiseCardIcon > img {
  width: 100px;
  color: white;
  transition: 500ms ease-in-out;
}
.whiteImg {
  display: none;
  transition: 500ms ease-in-out;
}
.blackImg {
  transition: 500ms ease-in-out;
}
.homeExpertiseCardContainer:hover .whiteImg {
  display: block;
}
.homeExpertiseCardContainer:hover .blackImg {
  display: none;
}
.homeExpertiseCardTxt > h2 {
  color: black;
  transition: 200ms ease-in-out;
}
.homeExpertiseCardTxt > h2 > a {
  color: black;
  text-decoration: none;
}
.homeExpertiseCardContainer:hover .homeExpertiseCardTxt > h2 > a {
  color: white;
}

.homeExpertiseCardContainer:hover .expIcon {
  color: white;
}
/* //////////////////////////////////////////////// */
.ExpertiseServiceCardContainer {
  border: 2px solid grey;
  border-bottom: 1px;
  border-right: 1px;
  height: 34vh;
  width: 33.3%;
  position: relative;
  overflow: hidden;
}
.ExpertiseServiceCardCover {
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  transition: 500ms ease-in-out;
  background-color: #202a36e6;
}
.ExpertiseServiceCardContainer > img {
  width: 100%;
  height: 100%;
}

.ExpertiseServiceCardTxtContainer {
  /* border: solid red; */
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ExpertiseServiceCardIcon {
  /* border: solid blue; */
  height: 50%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExpertiseServiceCardIcon > img {
  width: 100px;
}
.ExpertiseServiceCardTxt > h2 > a {
  color: white;
  transition: 200ms ease-in-out;
  text-decoration: none;
}
.ExpertiseServiceCardTxt :hover {
  color: #cb935d;
}
.expServIcon {
  font-size: 50px;
  color: white;
  transition: 500ms ease-in-out;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .homeExpertiseContainer {
    margin-top: 5%;
  }
  .homeExpertiseMainContainer {
    background-color: whitesmoke;
  }

  .homeExpertiseContainer {
    /* border: solid red; */
    height: 150vh;
  }
  .homeExpertiseContainer {
    /* border: solid blueviolet; */
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0%;
  }
  .homeExpertiseContent {
    /* border: solid; */
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .homeExpertiseQuote,
  .homeExpertiseHead,
  .homeExpertisePara {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  .homeExpertiseQuote > p {
    font-size: large;
  }
  .homeExpertiseHead > h1 {
    font-size: 40px;
    width: 100%;
    text-align: center;
  }
  .homeExpertiseHead > h1 > span {
    color: #a87e55;
  }

  .homeExpertisePara > p {
    /* border: solid; */
    width: 90%;
    text-align: center;
  }
  .homeExpertiseMainContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .homeExpertiseMainContainer {
    border: solid transparent;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .homeExpertiseCardContainer {
    border: 1px solid grey;
    height: 34vh;
    width: 90%;
    position: relative;
    overflow: hidden;
  }
  .homeExpertiseCardCover {
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    transition: 500ms ease-in-out;
  }
  .homeExpertiseCardContainer > img {
    width: 100%;
    height: 100%;
    transition: 500ms ease-in-out;
  }

  .homeExpertiseCardTxtContainer {
    /* border: solid red; */
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .homeExpertiseCardIcon {
    /* border: solid blue; */
    height: 50%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeExpertiseCardTxt > h2 {
    color: black;
    transition: 500ms ease-in-out;
  }

  .expIcon {
    font-size: 50px;
    color: black;
    transition: 500ms ease-in-out;
  }
  .homeExpertiseCardContainer:hover .expIcon {
    color: white;
  }
  /* //////////////////////////////////////////////// */
  .ExpertiseServiceCardContainer {
    border: 2px solid grey;
    border-bottom: 1px;
    border-right: 1px;
    height: 34vh;
    width: 90%;
    position: relative;
    overflow: hidden;
  }
  .ExpertiseServiceCardCover {
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    transition: 500ms ease-in-out;
    background-color: #202a36e6;
  }
  .ExpertiseServiceCardContainer > img {
    width: 100%;
    height: 100%;
  }

  .ExpertiseServiceCardTxtContainer {
    /* border: solid red; */
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ExpertiseServiceCardIcon {
    /* border: solid blue; */
    height: 50%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ExpertiseServiceCardTxt > h2 {
    color: white;
    transition: 200ms ease-in-out;
  }

  .expServIcon {
    font-size: 50px;
    color: white;
    transition: 500ms ease-in-out;
  }
}
@media screen and (min-width: 768px) {
  .homeExpertiseContainer {
    margin-top: 5%;
  }
  .homeExpertiseMainContainer {
    background-color: whitesmoke;
  }

  .homeExpertiseContainer {
    /* border: solid red; */
    height: 150vh;
  }
  .homeExpertiseContainer {
    /* border: solid blueviolet; */
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0%;
  }
  .homeExpertiseContent {
    /* border: solid; */
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .homeExpertiseQuote,
  .homeExpertiseHead,
  .homeExpertisePara {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  .homeExpertiseQuote > p {
    font-size: large;
  }
  .homeExpertiseHead > h1 {
    font-size: 40px;
    width: 100%;
    text-align: center;
  }
  .homeExpertiseHead > h1 > span {
    color: #a87e55;
  }

  .homeExpertisePara > p {
    /* border: solid; */
    width: 90%;
    text-align: center;
  }

  .homeExpertiseMainContainer {
    /* border: solid black; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .homeExpertiseCardContainer {
    border: 1px solid grey;
    height: 34vh;
    width: 90%;
    position: relative;
    overflow: hidden;
  }
  .homeExpertiseCardCover {
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    transition: 500ms ease-in-out;
  }
  .homeExpertiseCardContainer > img {
    width: 100%;
    height: 100%;
    transition: 500ms ease-in-out;
  }

  .homeExpertiseCardTxtContainer {
    /* border: solid red; */
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .homeExpertiseCardIcon {
    /* border: solid blue; */
    height: 50%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeExpertiseCardTxt > h2 {
    color: black;
    transition: 500ms ease-in-out;
  }

  .expIcon {
    font-size: 50px;
    color: black;
    transition: 500ms ease-in-out;
  }
  .homeExpertiseCardContainer:hover .expIcon {
    color: white;
  }
  /* //////////////////////////////////////////////// */
  .ExpertiseServiceCardContainer {
    border: 2px solid black;
    border-bottom: 1px;
    border-right: 1px;
    height: 34vh;
    width: 90%;
    position: relative;
    overflow: hidden;
  }
  .ExpertiseServiceCardCover {
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    transition: 500ms ease-in-out;
    background-color: #202a36e6;
  }
  .ExpertiseServiceCardContainer > img {
    width: 100%;
    height: 100%;
  }

  .ExpertiseServiceCardTxtContainer {
    /* border: solid red; */
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ExpertiseServiceCardIcon {
    /* border: solid blue; */
    height: 50%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ExpertiseServiceCardTxt > h2 {
    color: white;
    transition: 200ms ease-in-out;
  }

  .expServIcon {
    font-size: 50px;
    color: white;
    transition: 500ms ease-in-out;
  }
}
@media screen and (min-width: 990px) {
  .homeExpertiseContainer {
    margin-top: 5%;
  }
  .homeExpertiseMainContainer {
    background-color: whitesmoke;
  }

  .homeExpertiseContainer {
    /* border: solid red; */
    height: 150vh;
  }
  .homeExpertiseContainer {
    /* border: solid blueviolet; */
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0%;
  }
  .homeExpertiseContent {
    /* border: solid; */
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .homeExpertiseQuote,
  .homeExpertiseHead,
  .homeExpertisePara {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  .homeExpertiseQuote > p {
    font-size: large;
  }
  .homeExpertiseHead > h1 {
    font-size: 50px;
    width: 100%;
    text-align: center;
  }
  .homeExpertiseHead > h1 > span {
    color: #a87e55;
  }

  .homeExpertisePara > p {
    /* border: solid; */
    width: 50%;
    text-align: center;
  }

  .homeExpertiseMainContainer {
    border: 1px solid grey;
    border-top: 1px;
    border-left: none;
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .homeExpertiseCardContainer {
    border: 1px solid grey;
    border-bottom: 1px;
    border-right: 1px;
    height: 34vh;
    width: 33.3%;
    position: relative;
    overflow: hidden;
  }
  .homeExpertiseCardCover {
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    transition: 500ms ease-in-out;
  }
  .homeExpertiseCardContainer > img {
    width: 100%;
    height: 100%;
    transition: 500ms ease-in-out;
  }

  .homeExpertiseCardTxtContainer {
    /* border: solid red; */
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .homeExpertiseCardIcon {
    /* border: solid blue; */
    height: 50%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeExpertiseCardTxt > h2 {
    color: black;
    transition: 500ms ease-in-out;
  }

  .expIcon {
    font-size: 50px;
    color: black;
    transition: 500ms ease-in-out;
  }
  .homeExpertiseCardContainer:hover .expIcon {
    color: white;
  }
  /* //////////////////////////////////////////////// */
  .ExpertiseServiceCardContainer {
    height: 34vh;
    width: 33.3%;
    position: relative;
    overflow: hidden;
  }
  .ExpertiseServiceCardCover {
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    transition: 500ms ease-in-out;
    background-color: #202a36e6;
  }
  .ExpertiseServiceCardContainer > img {
    width: 100%;
    height: 100%;
  }

  .ExpertiseServiceCardTxtContainer {
    /* border: solid red; */
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ExpertiseServiceCardIcon {
    /* border: solid blue; */
    height: 50%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ExpertiseServiceCardTxt > h2 {
    color: white;
    transition: 200ms ease-in-out;
  }

  .expServIcon {
    font-size: 50px;
    color: white;
    transition: 500ms ease-in-out;
  }
}
