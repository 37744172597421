.homeHeaderContainer {
  /* border: solid yellow; */
  height: 100vh;
  width: 100%;
  position: relative;
}
.carouselContainer {
  /* border: solid green; */
  height: 100vh;
}
.carouselItem {
  /* border: solid red; */
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.carouselItem > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.bannerCover {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #131d29b8;
}
.homeHeaderTxtContainer {
  /* border: solid blueviolet; */
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  left: 10%;
}
.homeHeaderTxtContent {
  /* border: solid; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.homeHeaderHead > h2 {
  font-size: 70px;
  width: 100%;
}
.homeHeaderPara > p {
  /* border: solid; */
  width: 80%;
}
.homeHeaderTxtContent > button {
  border: 2px solid var(--customGold);
  width: 150px;
  height: 50px;
  background-color: var(--customBlue);
  box-shadow: 5px 5px 0px var(--customGold);
  color: white;
  transition: 200ms ease-in-out;
}
.homeHeaderTxtContent > button > a {
  text-decoration: none;
  color: white;
}
.homeHeaderTxtContent > button:hover {
  background-color: #a87e55;
  box-shadow: none;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .carouselItem {
    /* border: solid blue; */
    flex-direction: column;
  }
  .homeHeaderTxtContainer {
    /* border: solid blueviolet; */
    padding: 15px;
    left: 0;
  }
  .homeHeaderTxtContent {
    /* border: solid; */
    width: 100%;
    height: 100%;
    align-items: center;
  }
  .homeHeaderHead > h2 {
    font-size: 30px;
    width: 100%;
    text-align: center;
  }
  .homeHeaderPara > p {
    /* border: solid; */
    width: 100%;
    text-align: justify;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .homeHeaderTxtContent {
    /* border: solid; */
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
}
